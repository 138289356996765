import React, { useRef, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Box,
  Container,
  Center,
  Heading,
  UnorderedList,
  ListItem,
  Flex,
  Spacer,
  useMediaQuery,
  Link,
} from "@chakra-ui/react";

import { Link as GatsbyLink } from "gatsby";

import Paragraph from "../ui/common/Paragraph";
import EditorialStaff from "../pages/about/editorial-policies/editorial-board-and-staff";
import EditorialBoard from "../pages/about/editorial-policies/micropublication-biology-editorial-board";

const items = [
  {
    title: "Our Mission",
    text: (
      <Paragraph>
        microPublication.org publishes brief articles on research findings
        presented in a single figure to rapidly disseminate experimentally sound
        results to the community. These can be novel findings, negative results,
        replication successful (including being scooped), replication
        unsuccessful; perceived impact is not relevant. Each article is
        peer-reviewed, assigned a DOI, and your data is curated and deposited in
        third-party authoritative scientific databases upon publication. Over
        1,000 microPublication Biology articles are now discoverable through{" "}
        <Link href="https://www.ncbi.nlm.nih.gov/pmc/journals/3859/" isExternal>
          PMC
        </Link>
        ,{" "}
        <Link
          href="https://pubmed.ncbi.nlm.nih.gov/?term=%22MicroPubl+Biol%22%5Bjour%5D"
          isExternal
        >
          PubMed
        </Link>
        ,{" "}
        <Link
          href="http://europepmc.org/search?query=%28JOURNAL%3A%22Micropublication%20Biology%22%29"
          isExternal
        >
          EuropePMC
        </Link>
        ,{" "}
        <Link
          href="https://scholar.google.com/scholar?q=micropublication+biology"
          isExternal
        >
          Google Scholar
        </Link>
        , and university library catalogs.
      </Paragraph>
    ),
  },
  {
    title: "Aims and Scope",
    text: (
      <>
        <Paragraph>
          <i>microPublication Biology</i> is an open access journal that
          publishes research in all areas of life sciences. microPublication.org
          publishes single, validated findings that may be novel, reproduced,
          negative, lack a broader scientific narrative, or perceived to lack
          high impact. All published articles are peer-reviewed for scientific
          rigor and reproducibility. All published article are vetted for
          adherence to community standards in nomenclature and data reporting.
        </Paragraph>
        <Paragraph>
          microPublication aims to advance scholarly communication by
          short-circuiting the publication-to-database process, placing new
          findings directly into information discovery spaces upon publication.
          This is in addition to our articles being findable through general
          indexers such as PubMed, PubMedCentral and Google Scholar. In addition
          to being open access, we make data from our articles easier to access,
          by collaborating collaborations with community-directed authoritative
          databases e.g., WormBase, FlyBase, PomBase, ZFIN, etc.
        </Paragraph>
        <Paragraph>
          microPublication relies on curators of these databases to define
          copyediting guidelines that meet community nomenclature standards and
          reporting, cutting down on typos and alerting the databases to newly
          identified bioentities. The microPublication submission workflow
          includes database curators, allowing expert annotation and further
          data vetting after article acceptance. Upon publication, article
          metadata and curated data are exported to and integrated into these
          databases. As such, Seamlessly and behind the scenes, microPublication
          turns the scientific publishing process into a curatorial one.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Criteria for Publication",
    text: (
      <Paragraph>
        <i>microPublication</i> journals only accept high-quality data and work.
        Reported results are original work that has not been published
        elsewhere. Each submission includes a complete description of the result
        with accompanying reagents, resources, tools, and methodologies that
        were used in the experiment and any analysis. Appropriate controls and
        replicates are expected for all results. We publish only those articles
        that have been through our peer-review system. See{" "}
        <GatsbyLink to="/about/for-authors">
          <u>author guidelines</u>
        </GatsbyLink>{" "}
        for specific information about acceptable data. See our{" "}
        <GatsbyLink to="/about/for-authors#peer-review-process">
          <u>guidelines on peer-review</u>
        </GatsbyLink>{" "}
        for more information.
      </Paragraph>
    ),
  },
  {
    title: "Plagiarism Detection",
    text: (
      <Paragraph>
        As part of <i>microPublication Biology</i>'s commitment to protecting
        the integrity of the scholarly record, we employ iThenticate to detect
        plagiarism. iThenticate Similarity Reports provide an overall similarity
        index for each submission and inform editors on text copied from other
        sources without attribution. Plagiarized content will not be considered
        for publication. If plagiarism is identified, we will follow COPE{" "}
        <Link
          isExternal
          href="http://publicationethics.org/files/u2/02A_Plagiarism_Submitted.pdf"
        >
          guidelines
        </Link>
        .
      </Paragraph>
    ),
  },
  {
    title: "Protection of Human and Animal Subjects",
    text: (
      <Paragraph>
        Authors must follow guidelines recommended by the International
        Committee of Medical Journal Editors in the Uniform Requirements for
        Manuscripts Submitted to Biomedical Journals at{" "}
        <Link href="http://www.icmje.org" isExternal>
          http://www.icmje.org
        </Link>
        : “When reporting experiments on human subjects, authors should indicate
        whether the procedures followed were in accordance with the ethical
        standards of the responsible committee on human experimentation
        (institutional and national) and with the{" "}
        <Link
          href="https://www.wma.net/policies-post/wma-declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/"
          isExternal
        >
          Helsinki Declaration
        </Link>{" "}
        of 1975, as revised in 2008. If doubt exists whether the research was
        conducted in accordance with the{" "}
        <Link
          href="https://www.wma.net/policies-post/wma-declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/"
          isExternal
        >
          Helsinki Declaration
        </Link>
        , the authors must explain the rationale for their approach and
        demonstrate that the institutional review body explicitly approved the
        doubtful aspects of the study. When reporting experiments on animals,
        authors should indicate whether the institutional and national guide for
        the care and use of laboratory animals was followed.”
      </Paragraph>
    ),
  },
  {
    title: "Informed Consent/Privacy and Confidentiality",
    text: (
      <>
        <Paragraph>
          Authors must follow guidelines recommended by the International
          Committee of Medical Journal Editors in the Uniform Requirements for
          Manuscripts Submitted to Biomedical Journals at{" "}
          <Link href="http://www.icmje.org" isExternal>
            http://www.icmje.org
          </Link>
          : “Patients have a right to privacy that should not be violated
          without informed consent. Identifying information, including names,
          initials, or hospital numbers, should not be published in written
          descriptions, photographs, or pedigrees unless the information is
          essential for scientific purposes and the patient (or parent or
          guardian) gives written informed consent for publication. Informed
          consent for this purpose requires that an identifiable patient be
          shown the manuscript to be published. Authors should disclose to these
          patients whether any potential identifiable material might be
          available via the Internet as well as in print after publication.
          Patient consent should be written and archived with the journal, the
          authors, or both, as dictated by local regulations or laws."
          “Nonessential identifying details should be omitted. Informed consent
          should be obtained if there is any doubt that anonymity can be
          maintained…If identifying characteristics are altered to protect
          anonymity, such as in genetic pedigrees, authors should provide
          assurance, and editors should so note, that such alterations do not
          distort scientific meaning.”
        </Paragraph>
        <Paragraph>
          See our{" "}
          <GatsbyLink to="/privacy-notice">
            <u>Privacy Notice</u>
          </GatsbyLink>{" "}
          and the{" "}
          <GatsbyLink to="/gdpr">
            <u>General Data Protection Regulation Notice</u>
          </GatsbyLink>
          .
        </Paragraph>
      </>
    ),
  },
  {
    title: "Prior Publication Policy",
    text: (
      <Paragraph>
        <i>microPublication</i> does not accept submissions of work that have
        been published in peer-reviewed journals or repositories. We do not
        consider publication as an academic thesis, electronic preprint, or
        abstract as a prior publication.
      </Paragraph>
    ),
  },
  {
    title: "Readership",
    text: (
      <Paragraph>
        <i>microPublication</i> is an open-access journal available to anyone
        with online access. <i>microPublication Biology</i> in particular,
        publishes research relevant to all members of the science community
        interested in the biological sciences.
      </Paragraph>
    ),
  },
  {
    title: "Publication Support",
    text: (
      <Paragraph>
        <i>microPublication</i> is currently supported by funding from the
        National Library of Medicine. As part of our long-term sustainability
        plan, starting April 2nd, 2022 we will charge $250 per article, upon
        publication acceptance, to cover costs but not new initiatives. No
        article will be refused because of an author's inability to pay page
        charges.
      </Paragraph>
    ),
  },
  {
    title: "Why microPublish?",
    text: (
      <Paragraph>
        <i>microPublication Biology</i> accelerates scientific discovery by
        making technically sound research results freely open to the public
        through peer-reviewed publications and integration with other biomedical
        information via authoritative databases. Our articles provide
        researchers with credit for their findings through microPublication
        citations discoverable on PubMed.
      </Paragraph>
    ),
  },
  {
    title: "What Should You microPublish?",
    text: (
      <UnorderedList paddingLeft="1em">
        <ListItem>
          Exciting new research findings or reagents you want to rapidly place
          in the public domain; submitted microPublications can often be
          reviewed and published in <i>microPublication Biology</i> within a
          week.
        </ListItem>
        <ListItem>
          Experimental findings that did not fit into the narrative of an
          existing publication, and instead have remained in your lab
          notebook/file drawer/computer, ultimately unknown to the scientific
          community.
        </ListItem>
        <ListItem>
          New experimental findings that you do not anticipate fitting into
          future publications. Note: if your plans change, these findings can be
          included as a citable peer-reviewed publication that supports your
          work.
        </ListItem>
        <ListItem>
          An experimental finding that is viewed as a “negative result”, but is
          important for the field. For example, a null mutation in an organism
          that does not result in an obvious mutant phenotype (a wild-type null
          phenotype). Publication of these “negative results” provides
          potentially hypothesis-generating genetic information as well as can
          save other researchers from spending time and money repeating the same
          analysis.
        </ListItem>
        <ListItem>
          Experimental findings that provide valuable supporting information for
          a field – successful replication of recently published work, or just
          as important, cautionary information – unsuccessful replication of
          published work.
        </ListItem>
        <ListItem>
          Experimental findings included in an existing publication as an
          “unpublished observation” or ‘data not shown’ and thus not visible to
          the scientific community and not officially published.
        </ListItem>
        <ListItem>
          Experimental findings derived from small projects, for example
          undergraduate summer research projects, graduate rotation projects,
          that stand alone and are not necessarily part of a larger effort.
        </ListItem>
        <ListItem>
          You can include <i>microPublication Biology</i> articles in your
          <i>Curriculum Vitae</i>. Note: To avoid confusion with other
          publications, we recommend that your microPublication articles are
          included in a separate section with the heading “microPublications”
        </ListItem>
      </UnorderedList>
    ),
  },
  {
    title: "Who Can microPublish?",
    text: (
      <Paragraph>
        microPublication submissions are open to all levels of researchers.
        Whether you are a Principal Investigator (PI), postdoctoral researcher,
        a current or recent graduate student, undergraduate, or work in
        industry, <i>micropublishing</i> provides a route for you to receive
        credit for your findings and to get those data that do not fit into
        full-length articles into the public domain. Please note that
        manuscripts need to be approved for submission by the funding-supported
        PI or group head. PI approval can be entered through the online data
        submission form on the <i>microPublication Biology</i> site.
      </Paragraph>
    ),
  },
];

const AboutSection = (props) => {
  const { aboutRef: sectionRef, isPage, hash } = props;
  const title = "About microPublication";

  const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const ref = useRef([]);
  const [visibleSection, setVisibleSection] = useState();
  const [hashScrolled, setHashScrolled] = useState(false);
  const [isBig] = useMediaQuery("(min-width: 1024px)");

  useEffect(() => {
    if (hash && !hashScrolled) {
      const hashRef = hash.replace("#", "").replaceAll("-", " ");
      const index = items.findIndex(
        (item) => item.title.toLowerCase() === hashRef
      );
      index > 0 &&
        ref.current[index].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

      setHashScrolled(true);
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 200;

      const selected = ref.current.findIndex((ele) => {
        if (!ele) return null;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return null;
      });

      if (selected && selected !== visibleSection) {
        setVisibleSection(selected);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hash, visibleSection, hashScrolled]);

  if (isPage) {
    return (
      items &&
      items.length > 0 && (
        <>
          <Flex>
            <Box width="100px" />
            <Spacer />
            <Container maxWidth="7xl" paddingTop="150px">
              <Heading size="lg" color="gray" marginBottom="1em" align="center">
                {title}
              </Heading>

              {items.map((item, index) => {
                return (
                  <Container
                    key={`item-${index}`}
                    maxWidth="5xl"
                    borderWidth="1px"
                    borderRadius="lg"
                    p="5vh"
                    ref={(el) => (ref.current[index] = el)}
                    fontFamily="Noto sans, sans-serif"
                    marginBottom="1em"
                  >
                    <Heading size="md">{item.title}</Heading>
                    <>{item.text}</>
                  </Container>
                );
              })}
              <Container
                maxWidth="5xl"
                borderWidth="1px"
                borderRadius="lg"
                p="5vh"
                // ref={(el) => (ref.current[index] = el)}
                fontFamily="Noto sans, sans-serif"
                marginBottom="1em"
              >
                <EditorialBoard isSection />
              </Container>
              <Container
                maxWidth="5xl"
                borderWidth="1px"
                borderRadius="lg"
                p="5vh"
                //  ref={(el) => (ref.current[index] = el)}
                fontFamily="Noto sans, sans-serif"
                marginBottom="1em"
              >
                <EditorialStaff isSection />
              </Container>
            </Container>
            {isBig && (
              <>
                <Spacer />
                <Box minWidth="300px" />
              </>
            )}
          </Flex>
          {isBig && (
            <Container maxWidth="xs" position="fixed" right="0px" top="100px">
              <b>ON THIS PAGE</b>
              <UnorderedList marginLeft="30px" listStyleType="none">
                {items.map((item, index) => {
                  return (
                    <ListItem
                      onClick={() =>
                        ref.current[index].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        })
                      }
                      cursor="pointer"
                      _hover={{ textDecoration: "underline" }}
                      key={`contents-${index}`}
                    >
                      {visibleSection === index ? (
                        <b>{item.title}</b>
                      ) : (
                        item.title
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Container>
          )}
        </>
      )
    );
  }

  return (
    items &&
    items.length > 0 && (
      <Center>
        <Container
          maxW="7xl"
          borderWidth="1px"
          borderRadius="lg"
          p="10vh"
          ref={sectionRef}
          fontFamily="Noto sans, sans-serif"
        >
          <Heading size="lg" color="gray" marginBottom="1em">
            {title}
          </Heading>

          <Accordion allowToggle>
            {items.map((item, index) => {
              return (
                <AccordionItem key={`${title}-${index}`}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {item.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{item.text}</AccordionPanel>
                </AccordionItem>
              );
            })}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Editorial Board
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <EditorialBoard isSection />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Editorial Staff
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <EditorialStaff isSection />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Center>
    )
  );
};

export default AboutSection;

import React from "react";
import { Stack, Wrap, WrapItem, Heading } from "@chakra-ui/react";

import Profile from "../../../ui/common/Profile";

import Layout from "../../../components/Layout";

const imageDir = "/static/images";

const board = [
  {
    photo: "chu-diana.png",
    name: "Diana Chu",
    affiliation: "San Francisco State University, CA USA",
  },
  {
    photo: "conradt-barbara.png",
    name: "Barbara Conradt",
    affiliation: "Ludwig Maximilian University of Munich, Germany",
  },
  {
    photo: "driscoll-monica.png",
    name: "Monica Driscoll",
    affiliation: "Rutgers University, NJ USA",
  },
  {
    photo: "felix-marie-anne.png",
    name: "Marie Anne Felix",
    affiliation: "Institut de Biologie de l’Ecole Normale Supérieure, France",
  },
  {
    photo: "mello-craig.png",
    name: "Craig Mello",
    affiliation: "University of Massachusetts Medical School Worcester, MA USA",
  },
  {
    photo: "sawa-hitoshi.png",
    name: "Hitoshi Sawa",
    affiliation: "National Institute of Genetics, Japan",
  },
  {
    photo: "ward-jordan.png",
    name: "Jordan Ward",
    affiliation: "University of California Santa Cruz, CA USA",
  },
  {
    photo: "brian_oliver.jpg",
    name: "Brian Oliver",
    affiliation:
      "National Institute of Diabetes and Digestive and Kidney Diseases Bethesda, MD USA",
  },
];

const alumni = [
  {
    photo: "golden-andy.png",
    name: "Andy Golden",
    affiliation:
      "National Institute of Diabetes and Digestive and Kidney Diseases Bethesda, MD USA",
  },
  {
    photo: "kuwabara-patricia.jpg",
    name: "Patricia Kuwabara",
    affiliation: "University of Bristol, UK",
  },
];

const Profiles = () => {
  return (
    <Stack align="center" paddingTop="100px">
      <Heading color="gray">microPublication Biology Editorial Board</Heading>
      <Wrap maxW="6xl">
        {board.map((person) => (
          <WrapItem key={`board-${person.name}`}>
            <Profile {...person} photo={`${imageDir}/${person.photo}`} />
          </WrapItem>
        ))}
      </Wrap>

      <Heading color="gray">Alumni</Heading>
      <Wrap maxW="6xl">
        {alumni.map((person) => (
          <WrapItem key={`staff-${person.name}`}>
            <Profile
              {...person}
              photo={person.photo && `${imageDir}/${person.photo}`}
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};

const EditorialBoard = (props) => {
  const { isSection } = props;
  if (isSection) return <Profiles />;
  return <Layout children={<Profiles />} />;
};

export default EditorialBoard;
